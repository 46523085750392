<template>
  <div class="container">
    <FaqOverview />
  </div>
</template>

<script>
  import ssrHelper from '~/utils/ssr-helper'
  export default {
    layout: 'default',
    auth: false,
    async asyncData ({ params, error, route }) {
      let ssrPage = ssrHelper.fetchHead(route.name)
      let ssrArticles = ssrHelper.getFAQPages()
      return Promise.all([ssrPage, ssrArticles]).then((results) => {
        return {
          page: results[0],
          ssrPages: results[1]
        }
      }).catch((err) => error(err))
    },
    data: function () {
      return {
        page: undefined,
        ssrPages: undefined
      }
    },
    mounted() {
      this.getPageHead().then((res) => {
        this.page = res
      })
    },
    head() {
      let head = this.formatHead()
      if(this.ssrPages !== undefined) {
        let snippet = {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": this.ssrPages.filter((ssrPage) => {
            let isPage = ssrPage?.attributes?.is_page
            return isPage === undefined || isPage === true
          }).map((ssrPage) => {
            return {
              "@type": "Question",
              "name": ssrPage.title,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": ssrPage.content
              }
            }
          })
        }
        head.script = [
          { type: 'application/ld+json', json: snippet }
        ]
      }
      return head
    }
  };
</script>
