<template>
  <client-only>
    <div class="loginlayout">
      <Login />
      <Backdrop />
    </div>
  </client-only>
</template>

<script>
  import ssrHelper from '~/utils/ssr-helper'
  export default {
    auth: false,
    async asyncData ({ params, error, route }) {
      return ssrHelper.fetchHead(route.name).then((r) => {
        return { page: r }
      }).catch((err) => error(err))
    },
    data: function () {
      return {
        page: undefined
      }
    },
    mounted() {
      this.getPageHead().then((res) => {
        this.page = res
      })
    },
    head() {
      return this.formatHead()
    }
  }
</script>
