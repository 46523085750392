<template>
  <client-only>
    <section style="background-color:#fff;">
      <HeaderImageComponent />
      <ComponentSpacer />
      <Bullets />
      <Separator />
      <HowToOrder />
      <Separator classes="mb150" />
      <Playstore />
      <Content />
    </section>
  </client-only>
</template>

<script>
  import ssrHelper from '~/utils/ssr-helper'
  export default {
    layout: 'default',
    auth: false,
    async asyncData ({ params, error, route }) {
      return ssrHelper.fetchHead(route.name).then((r) => {
        return { page: r }
      }).catch((err) => error(err))
    },
    data() {
      return {
        bImg:{ backgroundImage:"url(/img/bgrestaurant-min.jpg)" },
        page: undefined
      };
    },
    mounted() {
      this.getPageHead().then((res) => {
        this.page = res
      })
    },
    methods: {
      logout() {
        this.$auth.logout();
        this.$router.replace("/");
      }
    },
    head() {
      return this.formatHead()
    }
  };
</script>
