<template>
  <client-only>
    <section class="card pagecard max-600 no-max-height not-found with-border px-0">
      <img src="/img/notfound.jpg" class="img-fluid" alt="not found" />
      <div class="content">
        <h3 class="title">{{ $t('notFound.title') }}</h3>
        <p>{{ $t('notFound.message') }}</p>
        <b-row>
          <b-col xs="12" class="text-right">
            <nuxt-link to="/" class="btn btn-outline uppercase">{{ $t('notFound.linkText') }}</nuxt-link>
          </b-col>
        </b-row>
      </div>
    </section>
  </client-only>
</template>
<script>
  import ssrHelper from '~/utils/ssr-helper'
  export default {
    layout: 'default',
    auth: false,
    async asyncData ({ params, error, route }) {
      return ssrHelper.fetchHead(route.name).then((r) => {
          return { page: r }
      }).catch((err) => error(err))
    },
    data: function () {
      return {
        page: undefined
      }
    },
    mounted() {
      this.getPageHead().then((res) => {
        this.page = res
      })
    },
    head() {
      return this.formatHead()
    }
  };
</script>
