<template>
  <client-only>
    <div class="loginlayout">
      <Login v-if="authType === 'login'" />
      <Register v-else-if="authType === 'register'" />
      <Backdrop />
    </div>
  </client-only>
</template>

<script>
  export default {
    auth: false,
    layout: 'embed',
    data: function () {
      return {
        page: undefined,
        authType: undefined
      }
    },
    mounted() {
      this.getPageHead().then((res) => {
        this.page = res
      })
      this.authType = this._.get(this.$route.params, 'type', 'login')

    }
  }
</script>
