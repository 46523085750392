<template>
  <client-only>
    <section :style="[ !isChildPage ? bImg : bImgDetail ]" :class="[ !isChildPage ? 'overview-image' : 'detail-image' ]">
      <Foodfilter :categories="foodFilterCategories" @change="userSelectedCategory" />

      <b-container v-if="loaded">
        <b-row v-if="!isChildPage">
          <LocationSelector :restaurant-filters="filterParams" @change="locationChange" />
          <RestaurantOverview :restaurant-filters="restaurantFilters" :seo="seo" @categoryChange="populateCategories" />
        </b-row>
        <b-row v-else>
          <RestaurantDetail :seo="ssrSeo" :restaurant-slug="restaurantDetailSlug" :product-filters="productFilters" @categoryChange="populateCategories" />
        </b-row>
      </b-container>
    </section>
  </client-only>
</template>

<script>
  import { get } from 'lodash-es'
  import ssrHelper from '~/utils/ssr-helper'
  import RestaurantSeoHelper from '~/utils/restaurant-seo-helper'
  export default {
    mixins: [
      RestaurantSeoHelper
    ],
    layout: 'default',
    auth: false,
    async asyncData ({ params, error, route }) {
      let promises = []
      promises.push(await ssrHelper.fetchHead(route.name))
      if(typeof window === 'undefined' && ['restaurant-detail', 'embed-restaurant-detail'].indexOf(route.name) >= 0) {
          promises.push(ssrHelper.getRestaurants())
      }
      return Promise.all(promises).then((values) => {
        let response = {
          ssrSeo: {
            page: get(values, '0')
          }
        }
        if(values.length > 1) {
          let filtered = get(values, '1', []).filter((restaurant) => restaurant.slug === params.slug)
          if(filtered.length) {
              response.ssrSeo.restaurant = get(filtered, '0')
          }
        }
        return response
      })
    },
    data: function() {
      return {
        page: undefined,
        loaded: false,
        bImg:{ backgroundImage:"url(/img/bgrestaurant-min.jpg)" },
        bImgDetail:{ backgroundImage:"url(/img/detailimage-min.jpg)" },
        isChildPage: false,
        restaurantDetailSlug: undefined,
        restaurant: undefined,

        foodFilterCategories: [],
        productFilters: {
          category_id: undefined
        },
        restaurantFilters: {
          countyId: undefined,
          cityId: undefined,
          category_id: undefined
        },
        filterParams: {
          county: undefined,
          city: undefined
        },
        seo: {
          city: undefined,
          county: undefined
        },
        ssrSeo: {
          page: undefined,
          restaurant: undefined
        }
      }
    },
    mounted() {
      this.getPageHead().then((res) => {
        this.page = res
      })
      this.parseRequestedPage()
    },
    methods: {
      parseRequestedPage () {
        this.isChildPage = ['restaurant-detail', 'embed-restaurant-detail'].indexOf(this.$route.name) >= 0;
        if(this.isChildPage)
        {
          this.restaurantDetailSlug = this._.get(this.$route.params, 'slug')
        } else {
          if (['restaurants-in-city', 'restaurants-in-county'].indexOf(this.$route.name) >= 0) {
            this.filterParams = {
              county: this._.get(this.$route.params, 'county'),
              city: this._.get(this.$route.params, 'city')
            }
          }
        }
        this.$nextTick(() => {
          this.loaded = true
        })
      },
      populateCategories (categories) {
        this.foodFilterCategories = categories
      },
      userSelectedCategory (category) {
        if (this.isChildPage) {
          this.productFilters.category_id = this._.get(category, 'id')
        } else {
          this.restaurantFilters.category_id = this._.get(category, 'id')
        }
      },
      locationChange (val) {
        this.restaurantFilters.countyId = val.countyId
        this.restaurantFilters.cityId = val.cityId
        this.seo.city = val.city
        this.seo.county = val.county
      }
    },
    head() {
      if (this.ssrSeo.restaurant && this.ssrSeo.page) {
        return this.buildHead(this.ssrSeo.page, this.ssrSeo.restaurant)
      } else {
        var title = this._.get(this.page, 'title', '')
          .replace('{city}', this.seo.city)
          .replace('{county}', this.seo.county)
        var meta_title = this._.get(this.page, 'meta_title', '')
          .replace('{city}', this.seo.city)
          .replace('{county}', this.seo.county)
        var meta_description = this._.get(this.page, 'meta_description', '')
          .replace('{city}', this.seo.city)
          .replace('{county}', this.seo.county)
        var meta = [
          {hid: 'title', name: 'title', content: meta_title},
          {hid: 'og:title', property: 'og:title', content: title},
          {hid: 'og:type', property: 'og:type', content: 'website'}
        ];
        if (meta_description.length) {
          meta.push({hid: 'description', name: 'description', content: meta_description})
          meta.push({hid: 'og:description', property: 'og:description', content: meta_description})
        }
        if (this.page?.og_image) {
          meta.push({hid: 'og:image', property: 'og:image', content: this.page?.og_image})
        }
        return {
          title: title,
          meta: meta
        }
      }
    }
  };
</script>
