<template>
  <section class="container">
    <OnesignalNotificationSubscribe :only-first-time="showNotificationSubscribeOnce"></OnesignalNotificationSubscribe>
    <AddressesWrapper v-if="allowedChildPages.adreseleMele.show"></AddressesWrapper>
    <Orders v-else-if="allowedChildPages.istoricComenzi.show"></Orders>
    <!--<Vouchers v-if="allowedChildPages.vouchere.show"></Vouchers>-->
    <Notifications v-else-if="allowedChildPages.notificari.show"></Notifications>
    <Profile v-else></Profile>
  </section>
</template>

<script>
  import ssrHelper from '~/utils/ssr-helper'
  export default {
    layout: 'default',
    auth: true,
    async asyncData ({ params, error, route }) {
      return ssrHelper.fetchHead(route.name).then((r) => {
        return { page: r }
      }).catch((err) => error(err))
    },
    data: function() {
      return {
        page: undefined,
        isChildPage: false,
        detailPageSlug: undefined,
        showNotificationSubscribeOnce: true,
        allowedChildPages: {
          adreseleMele: {
            show: false
          },
          istoricComenzi: {
            show: false
          },
          /*vouchere: {
            show: false
          },*/
          notificari: {
            show: false
          },
        }
      }
    },
    updated () {
      this.parseRequestedPage()
    },
    async mounted() {
      this.getPageHead().then((res) => {
        this.page = res
      })
      await this.parseRequestedPage()
    },
    methods: {
      async parseRequestedPage () {
        this.isChildPage = this.$route.name === 'my-account-subpage';
        if(this.isChildPage)
        {
          this.detailPageSlug = this._.camelCase(this._.get(this.$route.params, 'slug'))
          if(Object.keys(this.allowedChildPages).indexOf(this.detailPageSlug) >= 0) {
            this._.set(this.allowedChildPages, [this.detailPageSlug, 'show'], true)
          }else{
            this.$router.replace('/404')
          }
        } else {
          this.showNotificationSubscribeOnce = false
        }
      }
    },
    head() {
      return this.formatHead()
    }
  };
</script>
