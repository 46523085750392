<template>
  <section>
    <div class="container">
      <CartOverview />
    </div>
  </section>
</template>

<script>
  import Cart from "~/pages/Cart";
  export default {
    extends: Cart,
    layout: 'embed'
  };
</script>
