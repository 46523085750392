<template>
  <client-only>
    <section :style="[ !isChildPage ? bImg : bImgDetail ]" :class="[ !isChildPage ? 'overview-image' : 'detail-image' ]">
      <Foodfilter v-if="showProducts" :categories="foodFilterCategories" @change="userSelectedCategory" />

      <b-container v-if="loaded">
        <b-row v-if="!isChildPage">
          <LocationSelector :restaurant-filters="filterParams" @change="locationChange" />
          <RestaurantOverview :restaurant-filters="restaurantFilters" :seo="seo" @categoryChange="populateCategories" />
        </b-row>
        <b-row v-else>
          <RestaurantDetail
            :seo="ssrSeo"
            :restaurant-slug="restaurantDetailSlug"
            :product-filters="productFilters"
            :show-info="showInfo"
            :show-products="showProducts"
            :show-reviews="showReviews"
            :show-header="showHeader"
            @categoryChange="populateCategories"
          />
        </b-row>
      </b-container>
    </section>
  </client-only>
</template>

<script>
  import Restaurants from "~/pages/Restaurants";
  export default {
    extends: Restaurants,
    layout: 'embed',
    data () {
      return {
        showHeader: false,
        showInfo: false,
        showProducts: true,
        showReviews: false
      }
    },
    watch: {
      restaurantDetailSlug: {
        handler(embeddedRestaurantSlug) {
          this.$store.dispatch('embed/setEmbeddedRestaurantSlug', { embeddedRestaurantSlug })
        }
      }
    },
    mounted(){
      let that = this
      this.$root.$on('changeEmbedView', ({screen}) => this.switchScreen(screen))
      this.switchScreen(this._.get(this.$route.params, 'screen', 'products'))
    },
    methods: {
      switchScreen(screen){
        if(screen === 'info') {
          this.showInfo = this.showHeader = this.showReviews = true
          this.showProducts = false
        } else {
          this.showInfo = this.showHeader = this.showReviews = false
          this.showProducts = true
        }
      }
    }
  };
</script>
